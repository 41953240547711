export const LOGOS = [
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/egat_logo-min.jpg',
    alt: 'การไฟฟ้านครหลวง'
  },

  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/PTT_logo-min.jpg',
    alt: 'ปตท.'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/K-bank_logo-min.jpg',
    alt: 'ธนาคารกสิกร'
  },

  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/KTB-logo-min.jpg',
    alt: 'KTB'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/SCG_logo-min.jpg',
    alt: 'SCG'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/MU.jpg',
    alt: 'คณะแพทยศาสตร์ศิริราชพยาบาล'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/thaiairways_Logo-min.jpg',
    alt: 'การบินไทย'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/CU_Logo-min.jpg',
    alt: 'CU'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/CP_logo-min.jpg',
    alt: 'CP'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/AIA_logo-min.jpg',
    alt: 'AIA'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/Thaipakan_logo-min.jpg',
    alt: 'ไทยประกันชีวิต'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/workpoint_logo-min.jpg',
    alt: 'Workpoint'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/BDMS_logo-min.jpg',
    alt: 'BDMS'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/TTT_logo-min.jpg',
    alt: 'ททท'
  },
  {
    logoImg: 'https://d3u85r51ph21nf.cloudfront.net/reviews/logos/Kerrry_logo-min.jpg',
    alt: 'Kerry Express'
  }
]
