import React, { Component } from 'react'
// import Scroll from 'react-scroll'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import MobileHeroSection from 'src/components/MobileHeroSection'
import MobileTextPropSection from 'src/components/MobileTextPropSection'
import MobileExampleReed from 'src/components/MobileExampleReed'
import MobileProductCategory from 'src/components/MobileProductCategory'
import MobileCustomerReview from 'src/components/MobileCustomerReview'
import MobileHowToOrder from 'src/components/MobileHowToOrder'
import MobileReedDelivery from 'src/components/MobileReedDelivery'
import MobileFooter from 'src/components/MobileFooter'
import MobileStickyBottomButton from 'src/components/MobileStickyBottomButton'
import DesktopMenu from 'src/components/DesktopMenu'
import DesktopHeroSection from 'src/components/DesktopHeroSection'
import DesktopExampleReed from 'src/components/DesktopExampleReed'
import DesktopProductCategory from 'src/components/DesktopProductCategory'
import DesktopCustomerReview from 'src/components/DesktopCustomerReview'
import DesktopHowToOrder from 'src/components/DesktopHowToOrder'
import DesktopFooter from 'src/components/DesktopFooter'

import './style.scss'

export default class WatPage extends Component {
  render() {
    const {
      wat: {
        frontmatter: { name }
      }
    } = this.props
    return (
      <Layout>
        <SEO />
        <div className="mobile">
          <MobileHeroSection location={name} />
          <MobileTextPropSection />
          <MobileExampleReed />
          <MobileProductCategory />
          <MobileCustomerReview />
          <MobileHowToOrder />
          <MobileReedDelivery />
          <MobileStickyBottomButton />
          <footer>
            <MobileFooter />
          </footer>
        </div>
        <div className="desktop">
          <DesktopMenu />
          <DesktopHeroSection location={name} />
          <DesktopExampleReed />
          <DesktopProductCategory />
          <DesktopCustomerReview />
          <DesktopHowToOrder />
          <footer>
            <DesktopFooter />
          </footer>
        </div>
      </Layout>
    )
  }
}
