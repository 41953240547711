import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import './style.scss'

export default class ProductCategory extends Component {
  render() {
    return (
      <div className="product-category">
        <p className="product-cat-title">
          ท่านสามารถระบุข้อความในป้าย <br /> และเลือกได้หลายรูปแบบดังนี้...
        </p>
        <div className="category-wrapper">
          <Fade top delay={100}>
            <a
              className="cat-item"
              href="https://lin.ee/5L0cMzc?loso=C2"
              target="_blank"
              rel="noopener noreferrer"
              alt="@tanjai.co"
            >
              <img
                className="cat-bg cat-u img-responsive"
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/menu_catA_1.png"
                alt="พวงหรีดแบบประหยัด"
              />
              {/* <p className="cat-text cat-u">
                <span className="upper-text">พวงหรีด</span>
                แบบประหยัด
                <span className="lower-text">เริ่มต้น 990+ บาท</span>
              </p> */}
            </a>
          </Fade>
          <Fade top delay={300}>
            <a
              className="cat-item"
              href="https://lin.ee/5L0cMzc?loso=C3"
              target="_blank"
              rel="noopener noreferrer"
              alt="@tanjai.co"
            >
              <img
                className="cat-bg cat-u img-responsive"
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/menu_catB_1.png"
                alt="พวงหรีดแบบมาตรฐาน"
              />
              {/* <p className="cat-text cat-u">
                <span className="upper-text">พวงหรีด</span>
                แบบมาตรฐาน
                <span className="lower-text">เริ่มต้น 1,690+ บาท</span>
              </p> */}
            </a>
          </Fade>
          <Fade top delay={500}>
            <a
              className="cat-item"
              href="https://lin.ee/5L0cMzc?loso=C4"
              target="_blank"
              rel="noopener noreferrer"
              alt="@tanjai.co"
            >
              <img
                className="cat-bg cat-u img-responsive"
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/menu_catP_1.png"
                alt="พวงหรีด VIP"
              />
              {/* <p className="cat-text cat-u">
                <span className="upper-text">พวงหรีด</span>
                แบบVIP
                <span className="lower-text">เริ่มต้น 2,490+ บาท</span>
              </p> */}
            </a>
          </Fade>
          <Fade top delay={700}>
            <a
              className="cat-item"
              href="https://lin.ee/5L0cMzc?loso=C5"
              target="_blank"
              rel="noopener noreferrer"
              alt="@tanjai.co"
            >
              <img
                className="cat-bg cat-u img-responsive"
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/menu_catF_1.png"
                alt="พวงหรีดพัดลม"
              />
              {/* <p className="cat-text cat-u">
                <span className="upper-text">พวงหรีด</span>
                พัดลม
                <span className="lower-text">เริ่มต้น 1,990+ บาท</span>
              </p> */}
            </a>
          </Fade>
          <Fade top delay={900}>
            <a
              className="cat-item"
              href="https://lin.ee/5L0cMzc?loso=C6"
              target="_blank"
              rel="noopener noreferrer"
              alt="@tanjai.co"
            >
              <img
                className="cat-bg cat-u img-responsive"
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/menu_catU_1.png"
                alt="พวงหรีดของใช้"
              />
              {/* <p className="cat-text cat-u">
                <span className="upper-text">พวงหรีด</span>
                ของใช้
                <span className="lower-text">เริ่มต้น 990+ บาท</span>
              </p> */}
            </a>
          </Fade>
        </div>
        <p className="cat-note">
          เนื่องจากสินค้าของเราเป็นงานฝีมือ ทำด้วยใจของบางพื้นที่อาจสต็อคของไม่เหมือนกันโปรด Add
          line: @tanjai.co เพื่อสอบถาม
        </p>
      </div>
    )
  }
}
