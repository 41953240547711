import React, { Component } from 'react'
import Zoom from 'react-reveal/Zoom'

import './style.scss'

export default class MobileHeroSection extends Component {
  render() {
    const { location } = this.props
    return (
      <div className="hero">
        <div className="hero-title layout">
          <img
            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/logo_loso.png"
            alt="แทนใจ"
          />
          <h1>ร้านพวงหรีดออนไลน์</h1>
          <p>
            ส่งด่วนถึง{' '}
            <span>
              <img
                className="img-responsive"
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/pin_green.png"
                alt="pin"
              />
              {location ? location : 'วัด/ศาลา'}
            </span>
          </p>
        </div>
        <div className="hero-img">
          <a href="https://lin.ee/5L0cMzc?loso=B7" target="_blank" rel="noopener noreferrer">
            <img
              className="bg img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/hero_bg_mobile.png"
              alt="แทนใจพวงหรีด"
            />
          </a>
        </div>
        <div className="hero-prop layout">
          <Zoom delay={100}>
            <div className="prop-item">
              <p>
                <span className="checkmark">✓</span> ถึงวัด/จุดนัดหมาย{' '}
                <span className="highlight">ในวันนี้</span>
              </p>
            </div>
          </Zoom>
          <Zoom delay={300}>
            <div className="prop-item">
              <p>
                <span className="checkmark">✓</span> ขอออกใบกำกับภาษีได้
              </p>
            </div>
          </Zoom>
        </div>
      </div>
    )
  }
}
