import React, { Component } from 'react'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'
import './style.scss'

export default class DesktopMenu extends Component {
  render() {
    return (
      <header className="top-menu layout-padding">
        <div className="container">
          <div className="left-box">
            <img
              className="img-responsive"
              alt="แทนใจ"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/logo_loso.png"
            />
            <p>ร้านพวงหรีดออนไลน์</p>
          </div>
          <div className="right-box">
            <a href={`tel:${getTelFormatWithCountryCode(TEL)}`}>
              <div className="action-button call-button">
                <img
                  className="img-responsive"
                  alt="add line @tanjai.co"
                  src="https://d3u85r51ph21nf.cloudfront.net/premium/static/icon_telephone_white.png"
                />
                <p>โทรสอบถาม</p>
              </div>
            </a>
            <a href="https://lin.ee/5L0cMzc?loso=C8" target="_blank" rel="noopener noreferrer">
              <div className="action-button line-button">
                <img
                  className="img-responsive"
                  alt="add line @tanjai.co"
                  src="https://d3u85r51ph21nf.cloudfront.net/premium/static/icon_line_white.png"
                />
                <p>สั่งซื้อพวงหรีด</p>
              </div>
            </a>
          </div>
        </div>
      </header>
    )
  }
}
