import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import { HOME_IMG_LIST } from './Img/homeReviewImg'
import { LOGOS } from './Img/trustedCustomer'
import './style.scss'

export default class MobileExampleReed extends Component {
  render() {
    return (
      <div className="example-reed layout">
        <img
          className="img-responsive badge"
          alt="No.1 เรื่องพวงหรีด"
          src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/icon_no1.png"
        />
        <div className="reed-title">
          <p>วางใจได้ด้วย</p>
          <h2>ผลงานพวงหรีดมากมาย</h2>
        </div>
        <div className="reed-trusted">
          <div className="logos">
            {LOGOS.map((logo, index) => {
              return (
                <Fade key={index} delay={index * 200}>
                  <div className="logo-item">
                    <img src={logo.logoImg} alt={logo.alt} />
                  </div>
                </Fade>
              )
            })}
          </div>
          <div className="reeds">
            {HOME_IMG_LIST.map((item, index) => {
              return (
                <a
                  key={index}
                  href="https://lin.ee/5L0cMzc?loso=B5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="reed-item">
                    <img src={item.src} alt={item.alt} />
                  </div>
                </a>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
