import React, { Component } from 'react'
import HeadShake from 'react-reveal/HeadShake'
import Zoom from 'react-reveal/Zoom'

import './style.scss'

export default class MobileReedDelivery extends Component {
  render() {
    return (
      <div className="reed-delivery layout-padding">
        <HeadShake delay={300}>
          <p>
            <span>พวงหรีดถึงงาน</span> <br />
            มีรูปยืนยัน
          </p>
        </HeadShake>
        <div>
          <Zoom delay={500}>
            <img
              className="img-responsive"
              alt="ลูกค้าไว้วางใจ เลือกแทนใจ"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/review_customer.png"
            />
          </Zoom>
        </div>
      </div>
    )
  }
}
