export const HOME_IMG_LIST = [
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/001.jpg',
    alt: 'สั่งพวงหรีด วัดพระศรี จังหวัดกรุงเทพ'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/002.jpg',
    alt: 'สั่งพวงหรีด วัดกรอกยายชา จังหวัดระยอง'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/003.jpg',
    alt: 'สั่งพวงหรีด วัดเทพศิรินทร์ จังหวัดกรุงเทพ'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/006.jpg',
    alt: 'สั่งพวงหรีด วัดป่าสักหลวง จังหวัดเชียงใหม่'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/010.jpg',
    alt: 'สั่งพวงหรีด วัดปากคลองกุ้ง จังหวัดจันทบุรี'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/053.jpg',
    alt: 'สั่งพวงหรีด วัดวิชิตสังฆาราม จังหวัดภูเก็ต'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/025.jpg',
    alt: 'สั่งพวงหรีด วัดดงหนองเป็ด จังหวัดเชียงราย'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/026.jpg',
    alt: 'สั่งพวงหรีด วัดขจรรังสรรค์ จังหวัดภูเก็ต'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/027.jpg',
    alt: 'สั่งพวงหรีด วัดปากคลองกุ้ง จังหวัดจันทบุรี'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/028.jpg',
    alt: 'สั่งพวงหรีด วัดเทพศิรินทร์ จังหวัดกรุงเทพ'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/030.jpg',
    alt: 'สั่งพวงหรีด วัดพุทธมงคงนิมิต จังหวัดภูเก็ต'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/032.jpg',
    alt: 'สั่งพวงหรีด วัดหนองกาน้ำ จังหวัดชลบุรี'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/037.jpg',
    alt: 'สั่งพวงหรีด วัดลาดพร้าว จังหวัดกรุงเทพ'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/038.jpg',
    alt: 'สั่งพวงหรีด วัดขจรรังสรรค์ จังหวัดภูเก็ต'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/039.jpg',
    alt: 'สั่งพวงหรีด วัดพระศรี จังหวัดกรุงเทพ'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/042.jpg',
    alt: 'สั่งพวงหรีด วัดพระศรี จังหวัดกรุงเทพ'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/044.jpg',
    alt: 'สั่งพวงหรีด จังหวัดเชียงราย'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/045.jpg',
    alt: 'สั่งพวงหรีด จังหวัดจันทบุรี'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/046.jpg',
    alt: 'สั่งพวงหรีด จังหวัดประจวบคีรีขันธ์'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/048.jpg',
    alt: 'สั่งพวงหรีด จังหวัดร้อยเอ็ด'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/005.jpg',
    alt: 'สั่งพวงหรีด วัดนาคปรก จังหวัดกรุงเทพ'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/008.jpg',
    alt: 'สั่งพวงหรีด วัดโพธิวราราม จังหวัดอุดรธานี'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/014.jpg',
    alt: 'สั่งพวงหรีด วัดปากคลองกุ้ง จังหวัดจันทบุรี'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/033.jpg',
    alt: 'สั่งพวงหรีด วัดท่ามะปราง จังหวัดพิษณุโลก'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/034.jpg',
    alt: 'สั่งพวงหรีด วัดหนามแดง จังหวัดสมุทรปราการ'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/035.jpg',
    alt: 'สั่งพวงหรีด วัดพัฒนาราม จังหวัดสุราษฎร์ธานี'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/041.jpg',
    alt: 'สั่งพวงหรีด วัดเสมียนนารี จังหวัดกรุงเทพ'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/047.jpg',
    alt: 'สั่งพวงหรีด จังหวัดลำปาง'
  }
]
