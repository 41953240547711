import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import './style.scss'

export default class DesktopHowToOrder extends Component {
  render() {
    return (
      <div className="howto-order-d layout">
        <h2>ขั้นตอนสั่งพวงหรีด</h2>
        <div className="steps">
          <Fade left delay={100}>
            <div className="step-item">
              <a href="https://lin.ee/5L0cMzc?loso=A5" target="_blank" rel="noopener noreferrer">
                <img
                  className="img-responsive"
                  alt="เลือกสินค้า แคปรูป/แจ้งรหัส"
                  src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/howto_1_desktop.png"
                />
              </a>
            </div>
          </Fade>
          <Fade left delay={300}>
            <div className="step-item">
              <a href="https://lin.ee/5L0cMzc?loso=A6" target="_blank" rel="noopener noreferrer">
                <img
                  className="img-responsive"
                  alt="เลือกสินค้า แคปรูป/แจ้งรหัส"
                  src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/howto_2_desktop.png"
                />
              </a>
            </div>
          </Fade>
          <Fade left delay={500}>
            <div className="step-item">
              <a href="https://lin.ee/5L0cMzc?loso=A7" target="_blank" rel="noopener noreferrer">
                <img
                  className="img-responsive"
                  alt="เลือกสินค้า แคปรูป/แจ้งรหัส"
                  src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/howto_3_desktop.png"
                />
              </a>
            </div>
          </Fade>
        </div>
      </div>
    )
  }
}
