import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import { HOME_IMG_LIST } from './Img/homeReviewImg'
import { LOGOS } from './Img/trustedCustomer'
import './style.scss'

export default class DesktopExampleReed extends Component {
  render() {
    return (
      <div className="example-reed-d layout">
        <div className="title-section container">
          <p className="upper-text">
            <img
              className="img-responsive badge"
              alt="No.1 เรื่องพวงหรีด"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/icon_no1.png"
            />
            วางใจได้ด้วย
          </p>
          <h2>ผลงานพวงหรีดมากมาย</h2>
          <p>
            พวงหรีดเป็นสัญลักษณ์แห่งการไว้อาลัยและรำลึกถึง ผู้จากไป
            ถือเป็นการให้เกียรติผู้ล่วงลับในโอกาสสุดท้าย ‘แทนใจ’ พวงหรีดออนไลน์
            ขออำนวยความสะดวกจัดทำ และจัดส่งพวงหรีดของท่านให้ถึงวัด/สถานที่จัดส่ง
          </p>
        </div>
        <div className="container">
          <div className="reed-trusted">
            <div className="logos">
              {LOGOS.map((logo, index) => {
                return (
                  <Fade key={index} delay={index * 200}>
                    <div className="logo-item">
                      <img src={logo.logoImg} alt={logo.alt} />
                    </div>
                  </Fade>
                )
              })}
            </div>
            <div className="reeds">
              {HOME_IMG_LIST.map((item, index) => {
                return (
                  <a
                    key={index}
                    href="https://lin.ee/5L0cMzc?loso=A2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="reed-item">
                      <img src={item.src} alt={item.alt} />
                    </div>
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
